<template>
    <div class="portfolio-modal modal fade" id="portfolioModal1" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-content">
            <div class="close-modal" data-dismiss="modal">
                <div class="lr">
                    <div class="rl">
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-lg-offset-2">
                        <div class="modal-body" v-for="(project, index) in projects" :key="index"
                            :id="'portfolioModal1' + (index + 1)">
                            <div id="column">
                                <h2>{{ project.projeAdi }}</h2>
                                <img v-if="project.resimUrl" :src="project.resimUrl" class="img-responsive img-centered"
                                    alt="">
                                    <div v-else class="img-responsive img-centered"><iframe controls :src="project.videoUrl"
                                        title="YouTube video player" frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen></iframe></div>
                                <p>{{ project.aciklama }}</p>
                                <ul class="list-inline item-details">
                                    <li>Zaman:
                                        <strong>{{ project.zaman }}</strong>
                                    </li>
                                    <li>Konum:
                                        <strong>{{ project.konum }}</strong>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <button type="button" class="btn btn-default" data-dismiss="modal"><i class="fa fa-times"></i>
                            Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';


const firebaseApp = {
    apiKey: "AIzaSyD7Fw5h09yrs43jM1U-bbkqa_zeoyYNRdk",
    authDomain: "dilasokmen-d4c96.firebaseapp.com",
    databaseURL: "https://dilasokmen-d4c96-default-rtdb.firebaseio.com",
    projectId: "dilasokmen-d4c96",
    storageBucket: "dilasokmen-d4c96.appspot.com",
    messagingSenderId: "459592365584",
    appId: "1:459592365584:web:1e30d73b9958b82413bc95",
    measurementId: "G-QT2P546D5E"
};

firebase.initializeApp(firebaseApp);
export default {
    data() {
        return {
            /* projeAdi: '',
            resimUrl: '',
            aciklama: '',

            zaman: '',
            konum: '', */
            projects: [],
        };
    },
    /* mounted() {
        var database = firebase.database();
        var ref = database.ref('/projects');
        ref.orderByChild('giris').equalTo('Sanat').on('value', (snapshot) => {
            snapshot.forEach((childSnapshot) => {
                var childData = childSnapshot.val();
                this.projects.push({
                    projeAdi: childData.projeAdi,
                    resimUrl: childData.resimUrl,
                    aciklama: childData.aciklama,
                    zaman: childData.zaman,
                    konum: childData.konum,
                });
            });
        });
    }, */
    mounted() {
        var database = firebase.database();
        var ref = database.ref('/projects');
        ref.orderByChild('giris').equalTo('Sanat').on('value', (snapshot) => {
            snapshot.forEach((childSnapshot) => {
                var childData = childSnapshot.val();
                this.projects.push(childData);
            });
        });
    },

};
</script>